import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="Moradas.app" link="https://moradas.app" bg="linear-gradient(to right, #36394f 0%, #615a77 100%)" mdxType="ProjectCard">
  App for communities
  <br />
  Speed up administration and increase resident satisfaction
    </ProjectCard>
    <ProjectCard title="Silbato" link="https://silbato.app" bg="linear-gradient(to right, #df411f 0%, #df411f 100%)" mdxType="ProjectCard">
  The #1 alarm app for communities
  <br />
  Alert guards and the administration board of your community in case of emergencies and suspicious events so that they can help you promptly.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      